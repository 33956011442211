import { isNull, isUndefined } from 'lodash-es';

export default {
  default() {
    return null;
  },
  load(value) {
    return value;
  },
  dump(value) {
    return value;
  },
  isPresent(value) {
    return !isNull(value) && !isUndefined(value);
  },
};

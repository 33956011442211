import forOwn from 'lodash-es/forOwn';
import isFunction from 'lodash-es/isFunction';
import isUndefined from 'lodash-es/isUndefined';
import isNull from 'lodash-es/isNull';
import isObject from 'lodash-es/isObject';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import keys from 'lodash-es/keys';
import Types from './types';

// Helpers

function buildFieldDefinitions(mixinOptions, callback) {
  const fields = {};
  forOwn(mixinOptions, (opts, key) => {
    if (!opts) return true;
    const options = opts === true ? {} : opts;
    const defaultType = options.nested ? Types.Object : Types.Default;
    const type = options.type || defaultType;
    const defaultArg = 'default' in options ? options.default : type.default;
    let defaultFunc;
    if (isFunction(defaultArg)) {
      defaultFunc = defaultArg;
    } else {
      defaultFunc = () => defaultArg;
    }
    const definition = {
      required: options.required || false,
      loadFrom: options.loadFrom || key,
      load: options.load || type.load,
      dumpTo: options.dumpTo || key,
      dump: options.dump || type.dump,
      default: defaultFunc,
      isPresent: options.isPresent || type.isPresent,
      nested: options.nested || false,
    };
    if (definition.nested) {
      definition.requiredSubFields = options.requiredSubFields || [];
    }
    if (callback) Object.assign(definition, callback(options, key));
    fields[key] = definition;
    return true;
  });
  return fields;
}

function isBlankValue(value) {
  return isUndefined(value) || isNull(value) || value === '';
}

export function loadValue(value, options) {
  if (isBlankValue(value)) return options.default();
  return options.load(value);
}

export function dumpValue(value, options) {
  if (isBlankValue(value)) return options.default();
  return options.dump(value);
}

function hasErrors(errors) {
  const invalid = Object.keys(errors).some((key) => {
    const fieldErrors = errors[key];
    if (isObject(fieldErrors)) {
      return hasErrors(fieldErrors);
    }
    return fieldErrors?.length > 0;
  });
  return invalid || false;
}

// Mixin

export default (mixinOptions, definitionCallback) => {
  const definitions = buildFieldDefinitions(mixinOptions, definitionCallback);
  const fieldNames = Object.keys(definitions).sort();

  return {
    props: {
      values: {
        type: Object,
        default: () => {},
      },
      errors: {
        type: Object,
        default: () => {},
      },
      disabled: Boolean,
    },
    emits: ['reset'],
    data() {
      const loadedValues = this.loadValues(this.values);
      const change = {};
      fieldNames.forEach((key) => {
        change[key] = (value) => {
          this.fields.values[key] = value;
        };
      });
      return {
        fieldNames,
        definitions,
        validatingFieldNames: [],
        fields: {
          originalValues: cloneDeep(loadedValues),
          values: cloneDeep(loadedValues),
          errors: cloneDeep(this.loadErrors(this.errors)),
          change,
        },
      };
    },
    watch: {
      values: {
        handler(values, prevValues) {
          if (!isEqual(values, prevValues)) {
            const loadedValues = this.loadValues(values);
            this.fields.originalValues = cloneDeep(loadedValues);
            this.fields.values = cloneDeep(loadedValues);
          }
        },
        deep: true,
      },
      errors: {
        handler(errors) {
          this.fields.errors = cloneDeep(this.loadErrors(errors));
        },
        deep: true,
      },
    },
    computed: {
      fieldData() {
        return {
          original: this.dumpValues(this.fields.originalValues),
          changed: this.dumpValues(this.fields.values),
        };
      },
      hasChanged() {
        return !isEqual(this.fieldData.original, this.fieldData.changed);
      },
      requiredFieldNames() {
        return this.getRequiredFields(this.definitions);
      },
      presentFieldNames() {
        return this.getPresentFields(this.fields.values, this.fields.originalValues);
      },
      requiredFieldErrors() {
        return this.getRequiredFieldErrors(this.requiredFieldNames, this.presentFieldNames);
      },
      isPopulated() {
        return keys(this.requiredFieldErrors).length === 0;
      },
      isValid() {
        return !this.isInvalid;
      },
      isInvalid() {
        return hasErrors(this.fields.errors);
      },
    },
    methods: {
      getPresentFields(values, defaultValues) {
        const fields = {};
        forOwn(values, (value, key) => {
          const options = this.definitions[key];
          const defaultValue = defaultValues[key];
          if (typeof value === 'object' && keys(value).length > 0) {
            const subFields = this.getPresentFields(value, options.default() || defaultValue || {});
            if (keys(subFields).length > 0) {
              fields[key] = subFields;
            }
            return;
          }
          if (options) {
            if (options.isPresent(value, options)) {
              fields[key] = true;
            }
          } else if (
            (defaultValue !== undefined && defaultValue !== value) ||
            defaultValue === undefined
          ) {
            fields[key] = true;
          }
        });
        return fields;
      },
      getRequiredFields(options) {
        const fields = {};
        forOwn(options, (option, key) => {
          if (Array.isArray(options)) {
            fields[option] = true;
            return;
          }
          if (option && !option.nested && option.required) {
            fields[key] = true;
            return;
          }
          if (option && option.nested && option.requiredSubFields) {
            const subFields = this.getRequiredFields(option.requiredSubFields);
            if (keys(subFields).length > 0) {
              fields[key] = subFields;
            }
          }
        });
        return fields;
      },
      getRequiredFieldErrors(required, present) {
        const returnValue = {};
        forOwn(required, (field, key) => {
          if (keys(field).length > 0) {
            const errors = this.getRequiredFieldErrors(required[key], present[key] || []);
            if (keys(errors).length > 0) {
              returnValue[key] = errors;
            }
          } else if (present[key] === undefined) {
            returnValue[key] = [{ error: 'blank' }];
          }
        });
        return returnValue;
      },
      originalLoadValues(values) {
        const loadedValues = {};
        forOwn(definitions, (options, key) => {
          const value = values ? values[options.loadFrom] : undefined;
          loadedValues[key] = loadValue(value, options);
        });
        return loadedValues;
      },
      originalDumpValues(values) {
        const dumpedValues = {};
        forOwn(definitions, (options, key) => {
          const value = values ? values[key] : undefined;
          dumpedValues[options.dumpTo] = dumpValue(value, options);
        });
        return dumpedValues;
      },
      originalLoadErrors(errors) {
        const loadedErrors = {};
        forOwn(definitions, (options, key) => {
          let fieldErrors;
          const defaultErrors = options.nested ? {} : [];
          if (errors && errors[key]) {
            fieldErrors = errors[options.loadFrom] || defaultErrors;
          } else {
            fieldErrors = defaultErrors;
          }
          loadedErrors[key] = fieldErrors;
        });
        return loadedErrors;
      },
      loadValues(values) {
        return this.originalLoadValues(values);
      },
      dumpValues(values) {
        return this.originalDumpValues(values);
      },
      loadErrors(errors) {
        return this.originalLoadErrors(errors);
      },
      reset() {
        this.fields.values = cloneDeep(this.fields.originalValues);
        this.$emit('reset');
      },
      clearErrors() {
        forOwn(definitions, (options, key) => {
          const defaultErrors = options.nested ? {} : [];
          this.fields.errors[key] = defaultErrors;
        });
      },
    },
  };
};

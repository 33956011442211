import isEmpty from 'lodash-es/isEmpty';

export default {
  default() {
    return [];
  },
  load(values) {
    return values || this.default();
  },
  dump(values) {
    return values || this.default();
  },
  isPresent(values) {
    return !isEmpty(values);
  },
};

import isEmpty from 'lodash-es/isEmpty';
import BaseType from './base';

export default {
  ...BaseType,
  default() {
    return {};
  },
  load(value) {
    return value || null;
  },
  dump(value) {
    return value || null;
  },
  isPresent(value) {
    return !isEmpty(value);
  },
};

import castArray from 'lodash-es/castArray';
import includes from 'lodash-es/includes';
import without from 'lodash-es/without';
import keys from 'lodash-es/keys';
import cloneDeep from 'lodash-es/cloneDeep';
import Schema from './schema';
import Types from './types';

function Form(schemaOptions) {
  return {
    mixins: [
      Schema(schemaOptions, (options) => ({
        validators: castArray(options.validate || []),
      })),
    ],
    data() {
      return {
        validatingForm: 0,
      };
    },
    computed: {
      isEditable() {
        return !this.disabled && !this.isValidating;
      },
      isSubmittable() {
        return this.isEditable && this.isPopulated;
      },
      isValidating() {
        return this.validatingForm > 0 || this.validatingFieldNames.length > 0;
      },
    },
    emits: [
      'validate',
      'validate-field',
      'validated-field',
      'validated',
      'required-field-errors',
      'submit',
    ],
    methods: {
      validateForm() {
        return true;
      },
      async validateField(key) {
        const { validators } = this.definitions[key];
        if (!validators.length) return true;

        // Throw an error if the field is currently already validating
        if (includes(this.validatingFieldNames, key)) {
          throw new Error(`The field is already validating: ${key}`);
        }

        // Emit an event which indicates that validation started for the field
        this.$emit('validate-field', { key });

        // Enforces #isValidating to be true for the form
        this.validatingFieldNames.push(key);

        // Collect all field validators and wait for them to finish validation
        const { values } = this.fields;
        const value = values[key];
        const validations = validators.map((validator) => validator.call(this, value, key, values));
        const results = await Promise.all(validations);

        // Collect the error messages from the promise results
        const messages = [];
        results.forEach(({ isValid, message }) => {
          if (!isValid) messages.push(message);
        });
        const isValid = !messages.length;
        this.fields.errors[key] = messages;

        // Remove field from #validatingFieldNames
        this.validatingFieldNames = without(this.validatingFieldNames, key);

        // Emit and return validation result for the particular field
        this.$emit('validated-field', { key, isValid, messages });
        return isValid;
      },
      async validate() {
        if (this.isValidating) {
          throw new Error('The form is already validating');
        }
        this.$emit('validate');
        this.validatingForm += 1;
        this.clearErrors();
        await Promise.all([
          this.validateForm(),
          ...this.fieldNames.map((key) => this.validateField(key)),
        ]);
        this.validatingForm -= 1;
        this.$emit('validated', {
          isValid: this.isValid,
          messages: this.fields.errors,
        });
        return this.isValid;
      },
      async submit() {
        if (!this.isSubmittable) {
          if (keys(this.requiredFieldErrors).length > 0) {
            this.$emit('required-field-errors', this.requiredFieldErrors);
          }
          return false;
        }
        const isValid = await this.validate();
        if (isValid) {
          const { changed, original } = this.fieldData;
          this.$emit('submit', cloneDeep(changed), original);
        }
        return isValid;
      },
    },
  };
}

Object.assign(Form, { Types });

export default Form;

import { isBoolean, isNumber } from 'lodash-es';
import BaseType from './base';

export default {
  ...BaseType,
  isPresent(value) {
    if (isBoolean(value) || isNumber(value)) return true;
    return !!value;
  },
};
